import React from 'react';
import { Fragment } from 'react';
import SubHeader from "./../components/SubHeader";
import ServiceCard from "./../components/ServiceCard";
import Testimonials from "./../components/Testimonials";
import CTA from "./../components/CTA";
import SEO from "./../components/SEO"

const Home = () => {
  return (
    <Fragment>
    <SEO title="Home" />
      <SubHeader title_main="Our Services" title_sub="what we do"/>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-6 container mx-auto px-4 pb-24">
        <ServiceCard title="Chemical Free Teatment" body="Our chemical-free approach to bee removal in southeastern Texas means you don’t have to worry about toxic fumes in or around your building. We’re able to offer same-day service because our methods are non-invasive, safe for both humans and animals." />  
        <ServiceCard title="No Kill Removal" body="Using non-toxic treatments is just one way we’re committed to relocating bees without harming them. We understand the importance of bees in the natural ecosystem and strive to provide relocation services that don’t harm these creatures. After they’re tranquilized, we safely collect and relocate them so they can flourish in nature." />
        <ServiceCard title="One Year Guarantee" body="We’re so confident in our bee removal practices that we guarantee our work for a full year. If we miss any portion of the colony or swarms creep up after we’ve come through, give us a call and we’ll come back. We stand by our work and always aim to deliver thorough results."/>
        <ServiceCard title="Bee Prevention" body="We take the steps necessary to ensure that new bee will not return to your troubled area. By ensuring the location is sealed off along with any other potential nesting area along with treatments to remove the pheromone scents left by the honeycombs that can attract newcomers."/>
      </div>
      <CTA />
      <Testimonials />
    </Fragment>
  );
}

export default Home;
