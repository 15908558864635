import React from 'react'

export default function ServiceCard({title, body}) {
    return (
        <div className="mx-auto max-w-3xl bg-white rounded-lg p-9 transform hover:-translate-y-2">
            <h5 className="mt-4">{title}</h5>
            <p>{body}</p>
        </div>
    )
}
