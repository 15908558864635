import React from 'react'
import Button from "./Button"

export default function Header({title_main, title_sub, title_paragraph, ctalink, cta}) {
    return (
        <div className="mt-10 mb-10 mx-auto container px-4">
            <div className="sm:text-center md:text-left">
                <h2 className="tracking-tight font-bold text-gray-900">
                    {title_main}
                </h2>
                <h5 className="block text-yellow-400 -mt-4">{title_sub}</h5>
                <p className="mt-3 text-base text-gray-500 ">
                    {title_paragraph}
                </p>
                <div className="mt-5">
                    <div className="my-4">
                        {ctalink && <Button ctalink={ctalink} cta={cta} />}
                    </div>
                </div>
            </div>
        </div>
    )
}