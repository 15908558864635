import React from 'react';
import SubHeader from "./../components/SubHeader";

const posts = [
    {
      customer: 'Matt and Ash Salas',
      review: "Brody did a great job removing our hive! He was quick to make it out and was able to remove it the same day! He explained the process and answered all my questions....very interesting how it all works! If you need bees removed, this is your guy!"
    },
    {
      customer: 'Kem Rolf Ford',
      review: "Thanks to Brody’s Busy Bees for taking care of my unwanted visitors!! I would definitely recommend him. Very professional and took good care of the bees while doing a great job! THANKS AGAIN!!"
    },
    {
      customer: 'Ismael Leon',
      review: "Hands down, these guys are great! Fast response time, I had a few left and they came back to remove the remaining bees."
    },
  ]
  
  export default function Testimonials() {
    return (
        <div className="">
            <SubHeader title_main="Testimonials" title_sub="what are customers are saying"/>
            <div className="relative pt-4 pb-20 px-4 sm:px-6 lg:pt-6 lg:pb-24 lg:px-8 container mx-auto ">
                
                <div className="relative max-w-7xl mx-auto">
                <div className=" max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none ">
                    {posts.map((post) => (
                    <div key={post.customer} className="flex flex-col rounded-lg shadow-lg overflow-hidden transform hover:-translate-y-2">
                        <div className="flex-1 bg-white p-6 flex flex-col justify-between ">
                        <div className="flex-1">
                            <div className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">{post.customer}</p>
                            <p className="mt-3 text-base text-gray-800">{post.review}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
      </div>
    )
  }